import { css, Theme } from '@emotion/react';
import {
  createTypography,
  hideDuringPrint,
  hideFromPercy,
  mqPrint,
} from './base';

export const getDefaults = ({ colours, typography }: Theme) => css`
  body {
    color: ${colours.text.default};
    ${createTypography(typography.body01)};
  }

  #nprogress .bar {
    background: ${colours.interactive.primary};
  }

  button:active,
  div[role='button']:active,
  input:active {
    outline: none;
  }

  ${mqPrint} {
    @page {
      margin: 0;
    }

    body {
      margin: 0 1.6cm;

      .tf-v1-sidetab {
        ${hideDuringPrint}
        ${hideFromPercy}
      }
    }

    html {
      background: ${colours.background.main};
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }

    * {
      box-shadow: none !important;
    }
  }
`;
